import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import './Asstes/sass/style.scss';
import Header from './Components/Header';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { routes } from './Routes/routes';
import Footer from './Components/Footer';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL, frontEnd_API, header } from './Config/config';
import StoreNotFound from './Components/StoreNotFound';
import { useDispatch, useSelector } from 'react-redux';
import { addStore, removeStore } from './Store/Slices/storeSlice';
import Loader from './Components/Loader';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import { storeLoader } from './Store/Slices/homeLoader';

function App() {

  const [dataFound, setDataFound] = useState();
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [theme, setTheme] = useState();
  const user = useSelector((state) => state.user.value);
  const store = useSelector((state) => state.store.value);
  const localLoader = useSelector((state) => state.loader.value);

  useEffect(() => {
    // axios.post(frontEnd_API.verify, { domain: "rushi-shop.printfuse.in" }, header)
    axios.post(frontEnd_API.verify, { domain: (["localhost", "192.168.0.140"].includes(window.location.hostname)) ? 'first.printfuse.in' : window?.location?.hostname }, header)
      .then((res) => {
        console.log("res::", res);

        if (res.status) {
            // setLoader(false)
        }
        if (res?.status == 200) {
          setDataFound(res?.data?.data);
          dispatch(addStore(res?.data?.data));
          if (res?.data?.data.currentTheme) {
            setTheme(res?.data?.data.currentTheme);
          }
          document.title = res?.data?.data.name.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');;
        }
      })
      .catch((err) => {
        console.log(err);

        // if (err?.response?.status) {
        //   setLoader(false);
        // }
        if (err?.response?.status !== 200) {
          setDataFound(false)
          document.title = 'Store Not Found'
        }
      })

    if (window.location.reload) {
      dispatch(removeStore());
    }
  }, [dispatch])

  useEffect(() => {
    if (!user) {
      if (location.pathname.startsWith('/user')) {
        navigate('/');
      }
    }
  }, [location.pathname, navigate, user])

  useEffect(() => {
    console.log("localLoader::", localLoader);
      dispatch(storeLoader(1))
      if (localLoader != 1) {
        setLoader(true);
        setTimeout(() => {
          setLoader(false);
          window.location.reload();
        }, 2000)
      }
  }, [])

  const showHeader = location.pathname.endsWith('/checkout');

  return (
    <div className={`custom-align-page ${(theme) ? theme : 'theme-two'}`}>
      {
        loader ?
          <>
            <Loader />
          </>
          :
          !dataFound ?
            <StoreNotFound />
            :
            <>
              <div>
                {
                  (store?.logo) ?
                    < Helmet >
                      <link
                        rel="icon"
                        type="image/png"
                        href={BASE_URL + store?.logo}
                      />
                    </Helmet>
                    : < Helmet >
                      <link
                        rel="icon"
                        type="image/png"
                        href={require('./Asstes/Images/try-logo.png')}
                      />
                    </Helmet>
                }{(!showHeader) && <Header />}
                <Routes>
                  {
                    routes.map((item, index) => {
                      return (
                        <Route key={index} path={item.path} element={item.element} />
                      )
                    })
                  }
                </Routes>
              </div>
              {(!showHeader) && <Footer />}
            </>
      }
      <ToastContainer />
    </div >
  );
}

export default App;
