import React, { useEffect, useState } from 'react'
import { Accordion, Button, Card, Col, Container, Modal, Row, Tab, Tabs } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { BASE_URL, frontEnd_API, header } from '../../Config/config';
import axios from 'axios';
import FormComponent from '../../Components/FormComponent';
import { pattern } from '../../Config/Constant';
import { initializeRazorpay } from '../../Components/InitializeRazorPay';
import { removeCart } from '../../Store/Slices/cartSlice';
import moment from 'moment';
import { FaTruckFast } from "react-icons/fa6";
import { FaRegCreditCard } from "react-icons/fa6";
import { HiOutlineCreditCard } from 'react-icons/hi2';
import { AiOutlineBank } from 'react-icons/ai';
import { CiWallet } from 'react-icons/ci';

function CheckOutPage() {
    const storeData = useSelector((state) => state.store.value);
    const [user, setUser] = useState();
    const location = useLocation();
    const navigate = useNavigate();
    const [addressList, setAddressList] = useState();
    const [tab, setTab] = useState("address");
    const [selectedAddress, setSelectedAddress] = useState();
    const [global, setGlobal] = useState();
    const [paymentType, setPaymentType] = useState();
    const [cartData, setCartData] = useState();
    const [razorCode, setRazorCode] = useState();
    const [finalOrder, setFinalOrder] = useState();
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [orderData, setOrderData] = useState();

    useEffect(() => {
        if (location?.state?.data) {
            setCartData(location?.state?.data);
            console.log("location?.state?.data::", location?.state?.data);
        }
        else {
            navigate('/cart')
        }
    }, [location?.state?.data, navigate])

    const paymentList = [
        {
            icon: <FaRegCreditCard />,
            label: 'credit cards',
            value: 'creditCards'
        },
        {
            icon: <HiOutlineCreditCard />,
            label: 'debit cards',
            value: 'debitCards'
        },
        {
            icon: <AiOutlineBank />,
            label: 'net banking',
            value: 'netBanking'
        },
        {
            icon: <CiWallet />,
            label: 'eWallet',
            value: 'eWallet'
        },
        {
            icon: <AiOutlineBank />,
            label: 'UPI',
            value: 'UPI'
        }
    ]

    const getUserProfile = async () => {
        try {
            const { data } = await axios.get(frontEnd_API.getProfile, header)
            console.log("ProfileData::", data);
            setUser(data?.data)
        }
        catch (e) {
            console.log("err::", e);
        }
    }
    const getGlobalData = async () => {
        try {
            const { data } = await axios.get(frontEnd_API.getGlobal, header);
            setGlobal(data?.data);
            console.log("data::", data);
        }
        catch (e) {
            console.log("err::", e);
        }
    }
    const getAddressList = async () => {
        try {
            const { data } = await axios.put(frontEnd_API.getAddress, {}, header);
            setAddressList(data?.data);
            const check = data?.data?.find((item) => item?.isPrimary === 1)
            setSelectedAddress(check);
            console.log("data::", data);
        }
        catch (e) {
            console.log("err::", e);
        }
    }

    useEffect(() => {
        getAddressList();
        getGlobalData();
        getUserProfile();
    }, [])

    const handleAddressLoad = (item) => {
        console.log("FormData::", item?.data);
        getAddressList();
        setSelectedAddress(item?.data);
    }

    const handlePayment = async (storeData) => {
        const res = await initializeRazorpay();
        if (!res) {
            alert("Razorpay SDK Failed to load");
            return;
        }
        const options = {
            key: "rzp_test_pPhRuH9aNA8DOL",
            amount: (parseInt(storeData?.orderNetAmount) * 100),
            currency: "INR",
            name: storeData?.name || "Default Name",
            description: "Order Transaction",
            image: storeData?.logo ? `${BASE_URL}${storeData.logo}` : undefined,
            handler: function (response) {
                localStorage.removeItem('rzp_device_id');
                localStorage.removeItem('rzp_checkout_anon_id');
                setRazorCode(response.razorpay_payment_id);
                postOrderDetails(storeData);
            },
            prefill: {
                name: user?.name || "Guest User",
                email: user?.email || "guest@example.com",
                contact: user?.mobile || "0000000000",
            },
            notes: {
                address: "Razorpay Corporate Office",
            },
            theme: {
                color: "#3399cc",
            },
        };

        const paymentObject = new window.Razorpay(options);

        paymentObject.on('payment.failed', function (response) {
            alert("Payment failed or was canceled. Please try again.");
            window.location.reload();
        });

        paymentObject.open();
    };

    const handleOrderProcess = () => {
        const checkCouponId = cartData?.couponData.find((item) => item?.label === cartData?.couponCode)
        const storeData = {
            "couponId": (cartData?.couponCode) ? checkCouponId.value : 0,
            "couponCode": cartData?.couponCode,
            "orderSubTotalAmount": orderData?.orderSubTotalAmount,
            "orderDiscountAmount": orderData?.orderDiscountAmount,
            "orderCouponAmount": cartData?.couponAmount,
            "orderGrossAmount": orderData?.orderGrossAmount,
            "orderShippingChargeAmount": orderData?.orderShippingChargeAmount,
            "orderTaxAmount": orderData?.orderTaxAmount,
            "orderNetAmount": orderData?.orderNetAmount,
            "orderPaymentMode": (paymentType === "COD") ? "COD" : 'ONLINE',
            "orderPaymentModeName": (paymentType === "COD") ? "COD" : `RAZORPAY-${paymentType}`, "orderPaymentTransactionNumber": (razorCode) ?? (Math.floor(1000000 + Math.random() * 9000000) + moment().format('YYYYMMDDhhmmss')).toString(),
            "orderBillingName": selectedAddress?.name,
            "orderBillingEmail": selectedAddress?.email,
            "orderBillingMobile": selectedAddress?.mobile,
            "orderBillingAddressLine1": selectedAddress?.addressLine1,
            "orderBillingAddressLine2": selectedAddress?.addressLine2,
            "orderBillingArea": selectedAddress?.addressLine2,
            "orderBillingCity": selectedAddress?.city,
            "orderBillingState": selectedAddress?.state,
            "orderBillingCountry": "INDIA",
            "orderBillingPincode": selectedAddress?.pinCode,
            "orderShippingName": selectedAddress?.name,
            "orderShippingEmail": selectedAddress?.email,
            "orderShippingMobile": selectedAddress?.mobile,
            "orderShippingAddressLine1": selectedAddress?.addressLine1,
            "orderShippingAddressLine2": selectedAddress?.addressLine2,
            "orderShippingArea": selectedAddress?.addressLine2,
            "orderShippingCity": selectedAddress?.city,
            "orderShippingState": selectedAddress?.state,
            "orderShippingCountry": "INDIA",
            "orderShippingPincode": selectedAddress?.pinCode,
            "orderChildData": orderData?.productData
        }
        setFinalOrder(storeData)

        if (paymentType !== "COD") {
            handlePayment(storeData);
        }
        else {
            postOrderDetails(storeData);
        }
    }

    const postOrderDetails = (storeData) => {
        axios.post(frontEnd_API.postOrder, storeData, header)
            .then((res) => {
                console.log("DataFromOrder::", res);
                dispatch(removeCart());
                setShow(true);
                setTimeout(() => {
                    window.location.href = '/user/orders';
                }, 4200)
            })
            .catch((err) => {
                console.log("Err::", err);
            })
    }

    useEffect(() => {
        console.log("finalOrder::", finalOrder);
    }, [finalOrder])

    const handleTaxFromAPI = async () => {
        const tempProductData = cartData?.cartData?.map((item) => ({
            productId: item?.productId,
            variantData: item?.variantData,
            quantity: item?.quantity
        }))
        try {
            const response = await axios.put(frontEnd_API?.counttax, {
                productData: tempProductData,
                state: selectedAddress?.state,
                couponCode: cartData?.couponCode
            }, header)
            console.log("response::", response);
            if (response?.status == 200) {
                const data = response?.data?.data;
                setTab("payment");
                setCartData((prevData) => ({
                    ...prevData,
                    orderShippingChargeAmount: data?.orderShippingChargeAmount,
                    orderNetAmount: data?.orderNetAmount,
                    orderTaxAmount: data?.orderTaxAmount
                }))
                setOrderData(data)
            }
        }
        catch (e) {
            console.log("err::", e);
        }
    }

    useEffect(() => {
        console.log("cartData::", cartData);
    }, [cartData])

    return (
        <div>
            <Container fluid className='custom-checkout-page'>
                <Row className='pf-flex-lg-col-reverse'>
                    <Col className='col-12 col-lg-6 custom-checkout-page-steps py-4 py-md-4 pb-0 d-grid position-relative'>
                        <div>
                            <div className='position-sticky top-0'>
                                <div className='custom-img-holder-container d-flex align-items-center gap-3'>
                                    <Link to={'/'} className='custom-img-holder'>
                                        <img src={(storeData?.logo) ? BASE_URL + storeData?.logo : require('../../Asstes/Images/try-logo.png')} alt="" />
                                    </Link>
                                    <Link to={'/'} className='h3 text-decoration-none m-0 fw-bold text-capitalize d-none d-md-block me-3'>{storeData?.name}</Link>
                                    <Link to={'/'} className='h3 text-decoration-none m-0 fw-bold text-capitalize d-block d-md-none'>{storeData?.name.substring(0, 12)}{(storeData?.name.length > 12) && '...'}</Link>
                                </div>
                                <Tabs
                                    activeKey={(selectedAddress) ? tab : "address"}
                                    onSelect={(k) => setTab(k)}
                                    id="uncontrolled-tab-example"
                                    className="my-3 justify-content-evenly"
                                >
                                    <Tab eventKey="address" title="1. Address" className='custom-address-tab'>
                                        <Container className='pt-3 custom-address-tab-container gap-3 gap-sm-4 d-grid align-content-between'>
                                            <Row className='gy-3'>
                                                <Col className='col-12 custom-add-new-address'>
                                                    <Accordion>
                                                        {/* <Accordion defaultActiveKey={"0"}> */}
                                                        <Accordion.Item eventKey="0">
                                                            <Accordion.Header> <span className='d-flex gap-2 fw-semibold'><i className="bi bi-plus-circle-fill"></i> Add New Address</span> </Accordion.Header>
                                                            <Accordion.Body>
                                                                <FormComponent
                                                                    action={frontEnd_API.postAddress}
                                                                    onSubmit={handleAddressLoad}
                                                                    submitFullWidth={false}
                                                                    submitBTNAlign={'justify-content-start'}
                                                                    formMessage={"New Address Added !"}
                                                                    button={"Add"}
                                                                    fields={[
                                                                        {
                                                                            divClass: 'col-12 col-md-4 col-lg-12 col-xl-4',
                                                                            name: 'Name',
                                                                            key: 'name',
                                                                            required: true,
                                                                        },
                                                                        {
                                                                            type: 'email',
                                                                            divClass: 'col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4',
                                                                            name: 'Email',
                                                                            key: 'email',
                                                                            required: true,
                                                                            pattern: pattern.email
                                                                        },
                                                                        {
                                                                            type: 'number',
                                                                            divClass: 'col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4',
                                                                            name: 'Mobile',
                                                                            key: 'mobile',
                                                                            required: true,
                                                                            pattern: pattern.mobile
                                                                        },
                                                                        {
                                                                            divClass: 'col-12 col-sm-6',
                                                                            name: 'Address Line 1',
                                                                            key: 'addressLine1',
                                                                            required: true,
                                                                        },
                                                                        {
                                                                            divClass: 'col-12 col-sm-6',
                                                                            name: 'Address Line 2',
                                                                            key: 'addressLine2',
                                                                        },
                                                                        {
                                                                            type: 'select',
                                                                            options: global,
                                                                            divClass: 'col-6 col-sm-4',
                                                                            name: 'state',
                                                                            key: 'state',
                                                                            required: true,
                                                                            onChange: {
                                                                                from: 'children',
                                                                                to: 'city',
                                                                                options: global
                                                                            }
                                                                        },
                                                                        {
                                                                            divClass: 'col-6 col-sm-4',
                                                                            type: 'select',
                                                                            name: 'city',
                                                                            key: 'city',
                                                                            required: true,
                                                                            // options: ((updateAddress) && data.filter(item => item.value === updateAddress?.state)[0]?.children)
                                                                        },
                                                                        {
                                                                            divClass: 'col-12 col-sm-4',
                                                                            name: 'Pincode',
                                                                            key: 'pinCode',
                                                                            required: true,
                                                                        },
                                                                    ]}
                                                                />
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                </Col>
                                                <Col className='col-12 pt-2'>
                                                    <h4>Address List : </h4>
                                                </Col>
                                                {
                                                    (addressList?.length > 0) &&
                                                    addressList?.map((item, index) => (
                                                        <Col className='col-12 col-lg-6 col-xl-4 custom-address-list d-grid' key={index}>
                                                            <Card>
                                                                <Card.Header className='fw-semibold'>{item?.name}</Card.Header>
                                                                <Card.Body className='d-grid align-content-between'>
                                                                    {/* <Card.Title>Special title treatment</Card.Title> */}
                                                                    <Card.Text className='d-grid gap-1 gap-md-2'>
                                                                        <div className='d-flex gap-2'>
                                                                            <i className="bi bi-geo-alt-fill fs-14"></i>
                                                                            <address className='fs-14 m-0 fs-sm-12'>
                                                                                {item?.addressLine1}, {item?.addressLine2}, {item?.city}, {item?.state} - {item?.pinCode}
                                                                            </address>
                                                                        </div>
                                                                    </Card.Text>
                                                                    <button
                                                                        className={`fw-semibold flex-center-align gap-2 
                                                                        ${(selectedAddress?.id === item.id) ? 'active' : ''}`}
                                                                        onClick={() => setSelectedAddress(item)}
                                                                    >
                                                                        <i className="bi bi-check"></i>
                                                                        <span>{(selectedAddress?.id === item.id) ? 'Selected' : 'Select'}</span>
                                                                    </button>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                            <div className='pt-3 custom-next-btn'>
                                                <button
                                                    className={`fw-semibold ${(selectedAddress) ? 'active' : ''}`}
                                                    disabled={(selectedAddress) ? false : true}
                                                    onClick={() => handleTaxFromAPI()}
                                                >
                                                    {
                                                        (selectedAddress)
                                                            ? 'Next: Payment'
                                                            : 'Select Address'
                                                    }
                                                </button>
                                            </div>
                                        </Container>
                                    </Tab>
                                    <Tab eventKey="payment" title="2. Payment" disabled={(selectedAddress) ? false : true}>
                                        <Container className='flex-center-align'>
                                            <div className='text-center d-grid align-content-start gap-5 custom-payment-tab py-4 py-sm-5'>
                                                <div className='flex-center-align justify-content-start  flex-wrap gap-2 gap-md-3 gap-lg-4 px-4'>
                                                    {
                                                        (storeData?.isCodAvailable == 1) &&
                                                        <div
                                                            className={`payment-list-item d-flex gap-2 gap-sm-3 ${(paymentType === "COD") ? 'active' : ''}`}
                                                            onClick={() => setPaymentType("COD")}
                                                        >
                                                            <div
                                                                className='h4 m-0 fs-sm-16 flex-center-align gap-2 px-2 px-sm-3 py-1 py-sm-2' >
                                                                <FaTruckFast />
                                                                <span>COD</span>
                                                                <span>(Cash On Delivery)</span>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        paymentList.map((item, index) => (
                                                            <div
                                                                className={`payment-list-item d-flex gap-2 gap-sm-3 ${(paymentType === item?.value) ? 'active' : ''}`}
                                                                onClick={() => setPaymentType(item?.value)}
                                                                key={index}
                                                            >
                                                                <div
                                                                    className='h4 m-0 fs-sm-16 flex-center-align gap-2 px-2 px-sm-3 py-1 py-sm-2 text-capitalize'>
                                                                    {item?.icon}
                                                                    {item?.label}
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                <button
                                                    className={`custom-final-btn fs-18 fw-semibold ${(paymentType) ? 'active' : ''}`}
                                                    onClick={handleOrderProcess}
                                                    disabled={(!paymentType) ? true : false}
                                                >
                                                    Confirm Order
                                                </button>
                                            </div>
                                        </Container>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </Col>
                    <Col className='col-12 col-lg-6 custom-checkout-page-summary p-4 d-grid align-items-center'>
                        <div className='py-4 py-sm-5 px-0 px-sm-4 px-lg-0 px-xl-4'>
                            <div className='d-grid gap-4 gap-sm-5'>
                                <h3 className='fw-semibpold m-0'>Order summary</h3>
                                <div className='d-grid gap-3'>
                                    {
                                        (cartData?.cartData.length > 0) &&
                                        cartData?.cartData.map((item, index) => {
                                            const colorCode = item?.variantDetail?.filter(e => ['color', 'colors', 'colour', 'colours']?.includes(e?.parentName))?.map(e => e?.variantCode)
                                            return (
                                                <div className='order-product-list gap-2' key={index}>
                                                    <div className='img-holder'>
                                                        <img src={BASE_URL + item?.thumbnail} alt="" style={{ background: colorCode?.length > 0 ? colorCode[0] : '#ffffff' }} />
                                                    </div>
                                                    <div className='d-flex justify-content-between flex-wrap gap-2 w-100'>
                                                        <div className='d-grid gap-2 gap-sm-3 align-content-start'>
                                                            <h5 className='fw-semibold m-0 fs-sm-14'>{item?.productName}</h5>
                                                            <div className='d-flex gap-2 varient-list-data flex-wrap'>
                                                                {
                                                                    item?.variantDetail.map((varItem, varIndex) => (
                                                                        <span key={varIndex} className='px-2 py-1 fs-14 fs-sm-10 text-capitalize'>
                                                                            {varItem?.parentName}: {varItem?.variantName}
                                                                        </span>))
                                                                }
                                                                <span className='px-2 py-1 fs-14 fs-sm-10'>Qty : {item?.quantity}</span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <h5 className='fw-semibold m-0 fs-sm-14'>₹{item?.total}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className='d-grid gap-2 gap-sm-4'>
                                    <div className='list-calc d-grid gap-2'>
                                        <div className='flex-between-align fs-18 fs-sm-14 fs-lg-16'>
                                            <span>Items ({cartData?.cartData?.length})</span>
                                            <span>₹{cartData?.subTotalAmount}</span>
                                        </div>
                                        <div className='flex-between-align fs-18 fs-sm-14 fs-lg-16'>
                                            <span>Discount <span className='fs-14 fs-sm-10 fs-lg-12'>{cartData?.couponCode ? `(${cartData?.couponCode})` : ''}</span></span>
                                            <span>- ₹{cartData?.couponAmount}</span>
                                        </div>
                                        <div className='flex-between-align fs-18 fs-sm-14 fs-lg-16'>
                                            <span>Shipping <span className={`fs-14 fs-sm-10 fs-lg-12 ${cartData?.orderShippingChargeAmount ? "text-success fw-semibold" : "d-none"}`}>(Free Shipping)</span></span>
                                            <span className='d-flex gap-2 align-items-center'>
                                                <span className={`fs-sm-12 ${(!cartData?.orderShippingChargeAmount) ? 'fs-16' : ''}`}>
                                                    {
                                                        (cartData?.orderShippingChargeAmount) ?
                                                            <>- <span className='text-decoration-line-through'>₹{cartData?.orderShippingChargeAmount}</span></>
                                                            : "Calculated at next step"
                                                    }
                                                </span>
                                            </span>
                                        </div>
                                        <div className='flex-between-align fs-18 fs-sm-14 fs-lg-16'>
                                            <span>Tax</span>
                                            <span className='d-flex gap-2 align-items-center'>
                                                <span className={`fs-sm-12 ${(!cartData?.orderTaxAmount) ? 'fs-16' : ''}`}>
                                                    {
                                                        (cartData?.orderTaxAmount) ?
                                                            `+ ₹${cartData?.orderTaxAmount}`
                                                            : "Calculated at next step"
                                                    }
                                                </span>
                                            </span>
                                        </div>
                                        <hr className='mb-2 mb-sm-0' />
                                    </div>
                                    <div className='flex-between-align gap-2'>
                                        <h2 className='fw-semibold m-0 fs-sm-20'>Total (Inc. Tax)</h2>
                                        <h2 className='fw-semibold m-0 fs-sm-20'>₹{cartData?.orderNetAmount ?? cartData?.grossAmount}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Modal show={show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col className='col-12 flex-center-align'>
                                <div style={{ width: "300px", aspectRatio: "4/3" }}>
                                    <img src={require('../../Asstes/GIF/Animation - 1725267698496.gif')} alt="Description of the GIF" style={{
                                        objectFit: "contain",
                                        width: "100%",
                                        height: "100%"
                                    }} />
                                </div>
                            </Col>
                            <Col className='col-12 d-grid text-center'>
                                <h2 className='text-success fw-bold'>Order Placed Successfully !</h2>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default CheckOutPage
