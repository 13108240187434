import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FaApplePay, FaGooglePay } from 'react-icons/fa';
import { RiMastercardFill, RiVisaLine } from 'react-icons/ri';
import { SiRazorpay } from 'react-icons/si';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { frontEnd_API, header } from '../Config/config';

function Footer() {

    const storeData = useSelector((state) => state.store.value);

    const handleLink = (link) => {
        const newLink = link.toLowerCase().replace(/-/g, '~').replace(/\s+/g, '-');
        return newLink;
    }

    return (
        <div className='d-grid align-content-end'>
            <div fluid className='pt-5 pb-5 ps-0 pe-0 footerclr'>
                <Container>
                    <Row className='g-2 '>
                        <Col className='col-12 col-lg-4 p-2'>
                            <div className='p-2 f1 row'>
                                <div className='ft col-12'>CONTACT INFO</div>
                                <div className='col-12 cust-fd pt-3'>
                                    <div className='row'>
                                        <span className='col-2'>Phone:</span>
                                        <span className='col-8'>{storeData?.mobileNumber}</span>
                                    </div>
                                </div>
                                <div className='col-12 cust-fd'>
                                    <div className='row'>
                                        <span className='col-2'>Email: </span>
                                        <span className='col-8'>{storeData?.emailAddress ?? 'sampleemail@gmail.com'}</span></div>
                                </div>
                                <div className='col-12 cust-fd'>
                                    <div className='row'>
                                        <span className='col-2'>Address:</span>
                                        <span className='col-8'>
                                            {`
                                    ${storeData?.addressFirstLine}, 
                                    ${storeData?.addressSecondLine},
                                    ${storeData?.city},
                                    ${storeData?.state},
                                    ${storeData?.country} - 
                                    ${storeData?.pinCode}
                                    `}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className='col-6 col-lg-3 p-2'>
                            <div className='p-2 pt-0 f1 text-left'>
                                <div className='ft'>COMPANY</div>
                                <div className='fd pt-3 d-grid gap-1'>
                                    {
                                        (storeData?.storePageData?.length > 0) &&
                                        storeData?.storePageData?.map((item, index) => (
                                            <Link to={`/pages/${handleLink(item?.label)}`} key={index} className='text-capitalize'>{item?.label}</Link>
                                        ))
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col className='col-6 col-lg-2 p-2'>
                            <div className='p-2 pt-0 f1 text-left'>
                                <div className='ft text-uppercase pb-3'>Social Media</div>
                                {
                                    (storeData) &&
                                    storeData?.storeSocialData.map((item, index) => {
                                        return (
                                            <div key={index} className='fd'>
                                                <Link to={item.link} target='_blank' className="newfd text-capitalize d-flex gap-2">
                                                    <i className={`bi bi-${item.icon}`}></i>
                                                    {item.icon}
                                                </Link>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                        <Col className='col-12 col-lg-3 p-2 d-grid align-content-start gap-3'>
                            <div className='flex-between-align gap-2 justify-content-center justify-content-lg-end'>
                                <FaGooglePay className='fs-40 semi-border p-1 bg-light' />
                                <SiRazorpay className='fs-40 semi-border p-1 bg-light' />
                                <RiVisaLine className='fs-40 semi-border p-1 bg-light' />
                                <RiMastercardFill className='fs-40 semi-border p-1 bg-light' />
                                <FaApplePay className='fs-40 semi-border p-1 bg-light' />
                            </div>
                            <span className='text-center text-lg-end final-color'>Powered  by <Link className='fw-semibold text-decoration-none' target='_blank' to={'https://printfuse.in/'}>Printfuse</Link></span>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Footer
