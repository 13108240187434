import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { FaTruckFast } from "react-icons/fa6";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { BASE_URL, frontEnd_API, header } from '../../Config/config';
import { deleteCart, removeCart, updateCart } from '../../Store/Slices/cartSlice';
import { toast, ToastContainer } from 'react-toastify';
import { PiNoteBlank } from "react-icons/pi";
import { updateModal } from '../../Store/Slices/modalSlice';
import moment from 'moment';

function CartPage() {

    const cartData = useSelector((state) => state.cart.value);
    const userData = useSelector((state) => state.user.value);
    const modalData = useSelector((state) => state.modal.value);
    const [apiData, setApiData] = useState();
    const [applicableCoupon, setApplicableCoupon] = useState();
    const [pendingCoupon, setPendingCoupon] = useState();
    const [couponCode, setCouponCode] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const tempCart = cartData?.map((item) => ({
        "productId": item.productId,
        "variantData": Object.values(item?.variantData),
        "quantity": item.quantity
    }))

    const getCartList = async () => {
        try {
            // console.log("couponCode::", (couponCode)??"");
            const { data } = await axios.put(frontEnd_API.getCartList, {
                "cartData": tempCart,
                "couponCode": couponCode ?? "",
                "couponAmount": 0
            }, header)
            console.log("couponCode::", (couponCode) ?? "");
            setApiData(data?.data)
            const checkApplicable = data?.data?.couponData?.filter((item) => {
                return item?.limitCondition < data?.data?.subTotalAmount
            })
            setApplicableCoupon(checkApplicable);
            const checkPending = (checkApplicable?.length > 0)
                ? data?.data?.couponData?.filter((item) => {
                    return !checkApplicable.includes(item);
                })
                : data?.data?.couponData
            setPendingCoupon(checkPending);
            console.log("data::", data);
        }
        catch (e) {
            console.log("e::", e);
        }
    }

    useEffect(() => {
        getCartList();
    }, [cartData, couponCode])

    const deleteCartItem = (item) => {
        console.log("item::", item);
        if (window.confirm("Are you sure to remove item from cart ?")) {
            dispatch(deleteCart(item))
            getCartList();
            toast("Item removed from cart ! ")
        }
    }

    useEffect(() => {
        console.log("apiData::", apiData);
    }, [apiData])

    const handleCoupon = (item) => {
        console.log("item::", item);
        setCouponCode(item?.label)
        // getCartList();
    }

    const handleCartClear = () => {
        if (window.confirm("Are sure to clean card data ?")) {
            dispatch(removeCart());
            setCouponCode("");
        }
    }

    const handleCheckOut = () => {
        if (userData) {
            navigate('/checkout', {
                state: {
                    data: apiData
                }
            })
        }
        else {
            dispatch(updateModal(true))
        }
    }

    return (
        <div className='cartDetail ff-lexend'>
            <Container className='py-5'>
                {
                    (cartData?.length > 0) ?
                        <Row>
                            <Col className='col-12 col-lg-8 pe-4'>
                                <div style={{ position: 'sticky', top: '15px' }}>
                                    {
                                        (apiData?.cartData?.length > 0) &&
                                        <div className='d-grid gap-3'>{
                                            apiData?.cartData.map((item, index) => {

                                                const colorCode = item?.variantDetail?.filter(e => ['color', 'colors', 'colour', 'colours']?.includes(e?.parentName))?.map(e => e?.variantCode)
                                                console.log('color Code :: ', colorCode)
                                                return (
                                                    <>
                                                        <Row key={index} className='cart-card py-2 py-sm-3'>
                                                            <Col className='col-3 col-sm-2 ps-0 pe-1 pe-sm-2'>
                                                                <div className='cart-img-holder position-relative'>
                                                                    <img src={BASE_URL + item?.thumbnail} className='w-100' alt="" style={{ background: colorCode?.length > 0 ? colorCode[0] : '#ffffff' }} />
                                                                    <Link className='position-absolute top-0 start-0 w-100 h-100' to={`/product/${item?.productId}`}></Link>
                                                                </div>
                                                            </Col>
                                                            <Col className='col-9 col-sm-10 p-0 ps-auto'>
                                                                <div className='d-flex h-100'>
                                                                    <div className='w-100 d-grid align-items-start'>
                                                                        <div className='d-grid d-lg-flex justify-content-between cartProductsize'>
                                                                            <Link className='m-0' to={`/product/${item?.productId}`}>{item?.productName}</Link>
                                                                            <h5 className='m-0 text-start text-lg-end theme-color'><span className='text-secondary'>MRP</span> ₹{item?.total}</h5>
                                                                        </div>
                                                                        <div className='d-grid d-sm-flex qtyPlusMinuse pt-2'>
                                                                            {
                                                                                (item?.variantDetail?.length > 0) &&
                                                                                item?.variantDetail.map((varItem, varIndex) => (
                                                                                    <section className='p-1 px-lg-2' key={varIndex}>
                                                                                        {varItem?.parentName}:
                                                                                        <span className='ps-1 text-capitalize'>{varItem?.variantName}</span>
                                                                                    </section>
                                                                                ))
                                                                            }
                                                                            <section className='p-1 px-lg-2'>Qty: <span>{item?.quantity}</span></section>
                                                                        </div>
                                                                        <div className='fs-12 ps-1 p-color d-none d-sm-block'>Other Details </div>
                                                                        <hr className='m-0 mb-2 d-none d-sm-block' />
                                                                        <div className='d-none d-sm-flex align-items-center fs-14 theme-color'>
                                                                            <FaTruckFast /><section className='ps-1'>Est Delivery: <span>{moment().add(7, 'days').format('dddd, DD MMM YYYY')}</span></section>
                                                                        </div>
                                                                    </div>
                                                                    <div className='px-2 px-sm-4 px-lg-4 fs-20 customHeartColor d-grid align-content-between'>
                                                                        <Link onClick={() => deleteCartItem(index)}><i className="bi bi-trash3"></i></Link>
                                                                    </div>

                                                                </div>
                                                            </Col>
                                                            <Col className='cartItemPrd d-flex d-sm-none fs-12 py-2 mt-2 theme-color    '>
                                                                <FaTruckFast /><section className='ps-1'>Est Delivery: <span>Friday, 14 Sept 2024</span>.</section>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )
                                            })}
                                            <div
                                                className='text-danger fw-bold d-flex gap-1'
                                                style={{ cursor: 'pointer' }}
                                                onClick={handleCartClear}
                                            >
                                                <i class="bi bi-trash3-fill"></i>Clear All
                                            </div>
                                        </div>

                                    }
                                </div>
                            </Col>
                            <Col className='col-12 col-lg-4 p-0 mt-5 mt-lg-0'>
                                <div className='priceBox' style={{ position: 'sticky', top: '15px' }}>
                                    <div className='p-3'>
                                        <h5 className='fs-18 pb-2'>Price Details</h5>
                                        <div className='d-flex justify-content-between'>
                                            <span className='fs-16 w-100'>Subtotal</span>
                                            <span className='w-100 text-end'>₹{apiData?.subTotalAmount}</span>
                                        </div>
                                        <div className='d-flex justify-content-between' pt-1>
                                            <span className='fs-16 w-100'>Discount {`${apiData?.couponCode && `(${apiData?.couponCode})`}`}</span>
                                            <span className='w-100 text-end'>- ₹{parseInt(apiData?.couponAmount)}</span>
                                        </div>
                                        {/* <hr className='my-2' />
                            <div className='d-flex justify-content-between'>
                                <span className='fs-14 w-100'>Shipping & Handling (FREE - Blue Dart / Delhivery)</span>
                                <span className='w-100 text-end'>+ ₹0.00</span>
                            </div> */}
                                        <hr className='my-2' />
                                        <div className='d-flex justify-content-between fw-semibold'>
                                            <span className='fs-18 w-100'>Grand Total</span>
                                            <span className='fs-18 w-100 text-end'>₹{apiData?.grossAmount}</span>
                                        </div>
                                    </div>
                                    {
                                        (apiData?.couponAmount) ? <div className='fs-12 py-1 text-center bgPink'>
                                            <span>Congrats! You saved total <span className='text-success fs-14'>₹{apiData?.couponAmount}</span> on this order.</span>
                                        </div>
                                            : ''
                                    }
                                    <hr className='my-0' />
                                    {/* <div className='p-3'>
                            <h5 className='fs-18 pb-2'>Promo Code?</h5>
                            <div className='d-flex promoCodeInput'>
                                <input type="text" className='w-100 semiLightBtn text-uppercase' placeholder='Enter Code' />
                                <button >Apply</button>
                            </div>
                        </div>
                        <div className='fs-12 py-1 text-center bgPink'>
                            <span><Link>Login</Link> now to reveal your personalized coupon codes.</span>
                        </div> */}
                                    {(applicableCoupon?.length > 0) &&
                                        <div className='p-3 d-grid gap-3'>
                                            <h5 className='fs-18'>Applicable Coupon Codes</h5>
                                            {
                                                applicableCoupon.map((item, index) => (
                                                    <div key={index} className='coupenCodeDesign halfBorderRad d-grid p-3'>
                                                        <div className='coupenCodeDesign-div d-flex justify-content-between align-items-center fs-14'>
                                                            <section className='text-uppercase px-2 py-1 bgPink'>{item?.label}</section>
                                                            <button className={`py-1 base-btn halfBorderRad gap-1 
                                                    ${(couponCode === item?.label) ? 'active fw-semibold' : ''}`} onClick={() => handleCoupon(item)}>
                                                                <i className="bi bi-check fs-16"></i>
                                                                {(couponCode === item?.label)
                                                                    ? 'Applied' : 'Apply'}
                                                            </button>
                                                        </div>
                                                        <span className='pt-2 fs-14 text-secondary'>
                                                            Shop for ₹{item?.limitCondition} or more & get
                                                            {(item?.type === "AMT") ? ' ₹' : ' '}
                                                            {item?.digit}
                                                            {(item?.type !== "AMT") ? '%' : ''} off
                                                        </span>
                                                        <Link className='fs-12 fs-sm-12'>*T&C</Link>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    }
                                    {(pendingCoupon?.length > 0) ?
                                        <div className='p-3 d-grid gap-3'>
                                            <h5 className='fs-18'>Coupon Code List</h5>
                                            {
                                                pendingCoupon.map((item, index) => (
                                                    <div key={index} className='coupenCodeDesign halfBorderRad d-grid p-3'>
                                                        <div className='coupenCodeDesign-div d-flex justify-content-between align-items-center fs-14'>
                                                            <section className='text-uppercase px-2 py-1 bgPink'>{item?.label}</section>
                                                            <Link className='py-1 halfBorderRad base-btn theme-color-border' to={'/'} style={{ backgroundColor: 'transparent' }}>Shop More</Link>
                                                        </div>
                                                        <span className='pt-2 fs-14 text-secondary'>
                                                            Shop for ₹{item?.limitCondition} or more & get
                                                            {(item?.type === "AMT") ? ' ₹' : ' '}
                                                            {item?.digit}
                                                            {(item?.type !== "AMT") ? '%' : ''} off
                                                        </span>
                                                        <Link className='fs-12 fs-sm-12'>*T&C</Link>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        : ''
                                    }
                                    <div className='d-grid finalCheckOutBtn p-3 pt-1 pt-md-3 pb-0 pb-md-2 text-center footerForCart'>
                                        <button
                                            className='mt-2 half-border-rad fw-semibold'
                                            onClick={handleCheckOut}
                                        >Proceed to checkout</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        :
                        <div className='text-center d-grid align-items-center'
                            style={{ height: '50vh' }}>
                            <h1 className='flex-center-align gap-3 theme-color flex-wrap'><PiNoteBlank />Cart is Empty</h1>
                        </div>
                }
            </Container>
        </div >
    )
}

export default CartPage
