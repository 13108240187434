import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { BASE_URL, frontEnd_API, header } from '../../Config/config'
import { useSelector } from 'react-redux'

function Home() {

  const storeBanner = useSelector((state) => state.store.value);
  const params = useParams();
  const [productList, setProductList] = useState([]);
  const [bannerList, setBannerList] = useState([]);

  const getProducts = async () => {
    try {
      const categoryName = params?.categoryName ? params?.categoryName.replace('~', '-').replace('-', ' ') : "";
      const { data } = await axios.put(frontEnd_API?.getProducts, { category: categoryName }, header)
      setProductList(data?.data);
      console.log("data::", data?.data);
    }
    catch (e) {
      console.log("e::", e);
    }
  }

  useEffect(() => {
    getProducts();
    console.log(header);
    const newList = storeBanner?.storeBannerData.slice(1);
    setBannerList(newList);
    console.log("newList::", newList);
    console.log("params::", params);
  }, [params])

  return (
    <div className='custom-home-style'>
      {(storeBanner?.storeBannerData?.length > 0) &&
        < Container className='p-0 my-md-3' style={{ overflow: "hidden" }}>
          <Row className='gy-3 gy-md-3'>
            <Col className='col-12 px-3'>
              <div className='custom-img-holder'>
                <img src={BASE_URL + storeBanner?.storeBannerData[0]?.thumbnail} alt="" />
              </div>
            </Col>
            <div className='flex-center-align gap-3 px-3'>
              {
                bannerList?.map((item, index) => (
                  <div className='w-100 custom-img-holder' key={index}>
                    <img src={BASE_URL + item?.thumbnail} alt="" />
                  </div>
                ))
              }
            </div>
          </Row>
        </Container>}
      <Container className='py-4 py-sm-5'>
        <Row className='gy-4'>
          {/* Heading Part */}
          <Col className='col-12 d-grid d-sm-flex gap-3 justify-content-sm-between align-items-sm-center flex-column-rev'>
            <div className='d-flex d-sm-grid gap-1 justify-content-center'>
              <h4 className='m-0 fw-semibold text-capitalize'>{(params?.categoryName) ? params?.categoryName.replace('~', '-').replace('-', ' ') : "All Products"}</h4>
              <span className='p-color'>{productList?.length} items</span>
            </div>
            <div className='flex-between-align gap-2 justify-content-center'>
              <span className='p-color'>Sort by:</span>
              <select name="" id="" className='py-1 px-2 py-sm-2 px-sm-3'>
                <option value="">Newest arrivals</option>
                <option value="">Price: low to high</option>
                <option value="">Price: high to low</option>
              </select>
            </div>
          </Col>
          {
            (productList?.length > 0) ?
              productList.map((item, index) => {
                return (
                  <Col key={index} className='col-6 col-sm-4 col-lg-3'>
                    <div className='custom-img-holder-container d-grid gap-2'>
                      <Link className='custom-img-holder' to={`/product/${item?.id}`}>
                        <img src={BASE_URL + item.thumbnail} className='img-one' alt="" />
                        <img src={BASE_URL + item.thumbnailSlide} className='img-two' alt="" />
                      </Link>
                      <div className='custom-img-cnt d-grid'>
                        <Link className='h5 fw-semibold m-0 fs-sm-12 fs-md-14 fs-lg-16 fs-18' to={`/product/${item?.id}`}>{item?.name}</Link>
                        <span className='fw-bold pt-1 fs-sm-12 fs-md-14 fs-lg-16 fs-18'>
                          ₹{item?.price} <span className='fw-semibold text-decoration-line-through fs-sm-10 fs-md-12 fs-lg-14 fs-16'>₹{item?.mrp}</span>
                        </span>
                      </div>
                    </div>
                  </Col>
                )
              })
              :
              <h1 className='text-center'>Products not found</h1>
          }
        </Row>
      </Container>
    </div >
  )
}

export default Home
